import { Button, Dialog, DialogFooter, Spinner, SpinnerSize } from '@blueprintjs/core';
import { BrowserMultiFormatReader } from '@zxing/library';
import React, { SetStateAction, useEffect, useRef, useState } from 'react'

interface Props {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    animated: boolean;
    bordered: boolean;
    detectedText: string;
    setDetectedText: React.Dispatch<SetStateAction<string>>;
    scannedErrorText: string;
    setScannedErrorText: React.Dispatch<SetStateAction<string>>;
    videoRef: React.RefObject<HTMLVideoElement>;
    onChangeCamera: () => void;
    onSuccessScan: (text: string) => void;
    readerRef: React.MutableRefObject<BrowserMultiFormatReader | null>;
    setIsCameraActive: React.Dispatch<SetStateAction<boolean>>;
    mediaStream: MediaStream | null;
    setMediaStream: React.Dispatch<React.SetStateAction<MediaStream | null>>;
    setCurrentVideoRef: React.Dispatch<React.SetStateAction<HTMLVideoElement | null>>;
    setCurrentReaderRef: React.Dispatch<React.SetStateAction<BrowserMultiFormatReader | null>>;
}

const BarcodeScannerDialog = ({
    title,
    animated = true,
    bordered = true,
    isOpen,
    onClose,
    detectedText,
    setDetectedText,
    scannedErrorText,
    setScannedErrorText,
    videoRef,
    onChangeCamera,
    readerRef,
    setIsCameraActive,
    mediaStream,
    setMediaStream,
    setCurrentVideoRef,
    setCurrentReaderRef,
}: Props) => {
    const [isScanning, setIsScanning] = useState(false);

    useEffect(() => {
        if (isOpen) startScanning(localStorage.getItem("selectedCamera") as string);

        return () => stopScanning();
    }, [isOpen]);

    useEffect(() => {
        if (videoRef && animated) {
            const styleSheet = document.styleSheets[0];
            const keyframes = `
            @keyframes scan {
              0% {
                transform: translateY(0);
              }
              50% {
                transform: translateY(45px);
              }
              100% {
                transform: translateY(0);
              }
            }
          `;
            styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
            return () => {
                const index = Array.from(styleSheet.cssRules).findIndex(
                    (rule) => rule.cssText === keyframes
                );
                if (index > -1) {
                    styleSheet.deleteRule(index);
                }
            };
        }
    }, [videoRef, animated]);

    const startScanning = async (deviceId: string) => {
        try {
            stopScanning();
            setIsCameraActive(true);
            const stream = await navigator.mediaDevices.getUserMedia({
                video: { deviceId: { exact: deviceId } },
            });
            setMediaStream(stream);

            if (videoRef.current) {
                setIsScanning(true);
                videoRef.current.srcObject = stream;
                videoRef.current.onloadedmetadata = () => videoRef.current?.play();
                setCurrentVideoRef(videoRef.current);
                if (!readerRef.current) {
                    readerRef.current = new BrowserMultiFormatReader();
                    setCurrentReaderRef(readerRef.current);
                }

                readerRef.current.decodeFromVideoElement(videoRef.current).then((result) => {
                    if (result) {
                        setDetectedText(result.getText());
                    }
                });
            }
        } catch (error) {
            console.error('Error starting scanner:', error);
            setScannedErrorText("Error starting scanner");
        }
    };

    const stopScanning = () => {
        setDetectedText("");
        setScannedErrorText("");
        if (mediaStream) {
            mediaStream.getTracks().forEach((track) => track.stop());
            setMediaStream(null);
        }

        if (readerRef.current) {
            readerRef.current.reset();
            readerRef.current = null;
        }

        if (videoRef.current) {
            videoRef.current.srcObject = null;
        }
    };

    const handleRescan = () => {
        const deviceIdFromLocalStorage = localStorage.getItem("selectedCamera");
        stopScanning();
        if (deviceIdFromLocalStorage) {
            startScanning(deviceIdFromLocalStorage);
        }
    };

    return (
        <div
            style={{
                position: 'relative',
                zIndex: 2147483647,
            }}
        >

            <Dialog
                title={title || 'Scan Barcode'}
                isOpen={isOpen}
                onClose={() => {
                    onClose();
                    stopScanning();
                }}
                canOutsideClickClose={false}
                canEscapeKeyClose={false}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '300px',
                        textAlign: 'center',
                        padding: '20px',
                    }}
                >
                    <p
                        style={{
                            color: 'black',
                            fontWeight: 'bold',
                            fontSize: '14px',
                            marginBottom: '10px',
                        }}
                    >
                        Please place your barcode inside the rectangle
                    </p>

                    {/* Container for video with corners */}
                    <div
                        style={{
                            position: 'relative',
                            width: '90%', // Wider surface
                            height: '50px', // Shorter height
                            border: '2px solid transparent',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: '999'
                        }}
                    >
                        {bordered && (
                            <>
                                {/* Corners */}
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '-4px',
                                        left: '18px',
                                        width: '1rem',
                                        height: '1rem',
                                        borderTop: `2px solid ${detectedText ? 'rgb(45 151 43)' : 'rgb(59 130 246)'}`,
                                        borderLeft: `2px solid ${detectedText ? 'rgb(45 151 43)' : 'rgb(59 130 246)'}`,
                                        borderTopLeftRadius: '5px',
                                    }}
                                />
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '-4px',
                                        right: '18px',
                                        width: '1rem',
                                        height: '1rem',
                                        borderTop: `2px solid ${detectedText ? 'rgb(45 151 43)' : 'rgb(59 130 246)'}`,
                                        borderRight: `2px solid ${detectedText ? 'rgb(45 151 43)' : 'rgb(59 130 246)'}`,
                                        borderTopRightRadius: '5px',
                                    }}
                                />
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: '-4px',
                                        left: '18px',
                                        width: '1rem',
                                        height: '1rem',
                                        borderBottom: `2px solid ${detectedText ? 'rgb(45 151 43)' : 'rgb(59 130 246)'}`,
                                        borderLeft: `2px solid ${detectedText ? 'rgb(45 151 43)' : 'rgb(59 130 246)'}`,
                                        borderBottomLeftRadius: '5px',
                                    }}
                                />
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: '-4px',
                                        right: '18px',
                                        width: '1rem',
                                        height: '1rem',
                                        borderBottom: `2px solid ${detectedText ? 'rgb(45 151 43)' : 'rgb(59 130 246)'}`,
                                        borderRight: `2px solid ${detectedText ? 'rgb(45 151 43)' : 'rgb(59 130 246)'}`,
                                        borderBottomRightRadius: '5px',
                                    }}
                                />
                            </>
                        )}
                        {animated && !detectedText && !scannedErrorText && (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: '23px',
                                    width: '89%',
                                    height: '2px',
                                    backgroundColor: 'rgb(59 130 246)',
                                    animation: `scan 2s linear infinite`,
                                    zIndex: '999'
                                }}
                            />
                        )}
                        <div
                            style={{
                                position: 'relative',
                                width: '90%', // Wider surface
                                height: '50px', // Shorter height
                                border: '2px solid transparent',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <video
                                ref={videoRef}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    borderRadius: '0.375rem',
                                    pointerEvents: 'none',
                                }}
                                autoPlay
                                muted
                            />
                        </div>
                    </div>
                    <Button style={{ marginTop: '10px', fontSize: '12px', color: '#007bff' }} onClick={() => {
                        stopScanning();
                        onChangeCamera();
                    }} minimal>Change Camera</Button>
                    {isScanning && !detectedText && (
                        <div style={{ marginTop: '20px' }}>
                            <Spinner size={SpinnerSize.SMALL} />
                            <p style={{ color: '#5c7080', fontSize: '12px' }}>Scanning...</p>
                        </div>
                    )}
                    {detectedText && (
                        <p style={{ color: 'green', fontWeight: 'bold', fontSize: '14px', marginTop: '20px' }}>
                            Code scanned: {detectedText}
                        </p>
                    )}
                    {scannedErrorText && (
                        <p style={{ color: 'red', fontWeight: 'bold', fontSize: '14px', marginTop: '10px' }}>
                            {scannedErrorText}
                        </p>
                    )}
                </div>
                <DialogFooter
                    actions={
                        [
                            <Button onClick={handleRescan} intent="primary">
                                Re-Scan
                            </Button>,
                            <Button
                                onClick={onClose}
                                intent="success"
                                disabled={!detectedText}
                                style={{ marginLeft: '10px' }}>
                                Confirm Scan
                            </Button>
                        ]
                    }
                />

            </Dialog>
        </div>
    )
}

export default BarcodeScannerDialog;