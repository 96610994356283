import { Button, ButtonGroup, Dialog } from '@blueprintjs/core';
import { BrowserMultiFormatReader } from '@zxing/library';
import React, { SetStateAction, useEffect, useState } from 'react'

interface Props {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  cameras: MediaDeviceInfo[];
  setCameras: React.Dispatch<SetStateAction<MediaDeviceInfo[]>>;
  selectedCamera: string | null;
  setSelectedCamera: React.Dispatch<SetStateAction<string | null>>;
  switchToScannedDialog: () => void;
  videoRef: React.RefObject<HTMLVideoElement>;
  readerRef: React.MutableRefObject<BrowserMultiFormatReader | null>;
  mediaStream: MediaStream | null;
  setMediaStream: React.Dispatch<React.SetStateAction<MediaStream | null>>;

}

const CameraSelectionDialog = ({
  isOpen,
  onClose,
  title = 'Select your camera',
  cameras,
  setCameras,
  selectedCamera,
  setSelectedCamera,
  switchToScannedDialog,
  mediaStream,
  setMediaStream,
  videoRef,
  readerRef
}: Props) => {

  useEffect(() => {
    if (isOpen) initializeCameraPermissions();
  }, [isOpen])

  const initializeCameraPermissions = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ video: true });
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter((device) => device.kind === 'videoinput');
      console.log("videoDevices-> ", JSON.stringify(videoDevices));
      setCameras(videoDevices);
    } catch (error) {
      console.error('Camera permissions error:', error);
    }
  };

  const stopCurrentStream = () => {
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => track.stop());
      setMediaStream(null);
    }

    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }

    if (readerRef.current) {
      readerRef.current.reset();
      readerRef.current = null;
    }
  };

  const handleCameraSelection = async (deviceId: string) => {
    stopCurrentStream();
    try {
      setSelectedCamera(deviceId);
      localStorage.setItem("selectedCamera", deviceId);
      switchToScannedDialog();
    } catch (error) {
      console.error("Error al seleccionar la cámara:", error);
    }
  };


  return (
    <div
      style={{
        position: 'relative',
        zIndex: 2147483647,
      }}
    >
      <Dialog
        title={title || 'Scan Barcode'}
        isOpen={isOpen}
        onClose={onClose}
        canOutsideClickClose={false}
        canEscapeKeyClose={false}
        style={{
          zIndex: '2147483647'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '300px',
            textAlign: 'center',
            padding: '20px',
          }}
        >
          <p>Please select a camera:</p>
          <ButtonGroup
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              alignItems: 'center',
              maxHeight: '200px',
              overflowY: 'auto',
              width: '100%',
            }}
          >
            {cameras.map((camera) => (
              <Button
                key={camera.deviceId}
                style={{
                  textAlign: 'center',
                  backgroundColor: '#007bff',
                  color: '#fff',
                  padding: '10px 20px',
                  borderRadius: '5px',
                  fontWeight: 'bold',
                  width: '100%',
                }}
                onClick={() => handleCameraSelection(camera.deviceId)}
              >
                {camera.label || `Camera ${camera.deviceId}`}
              </Button>
            ))}
          </ButtonGroup>
        </div>
      </Dialog>
    </div>

  )
}

export default CameraSelectionDialog