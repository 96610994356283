import React, { SetStateAction, useEffect, useRef, useState } from 'react'
import CameraSelectionDialog from './CameraSelectionDialog';
import BarcodeScannerDialog from './BarcodeScannerDialog';
import { BrowserMultiFormatReader } from '@zxing/library'

interface Props {
  isOpen: boolean;
  onClose: () => void;
  setScannedText: React.Dispatch<SetStateAction<string>>;
  scannedText: string;
  animated: boolean;
  bordered: boolean;
}

const BarcodeDialogContainer = ({
  isOpen,
  onClose,
  setScannedText,
  scannedText,
  animated = true,
  bordered = true
}: Props) => {
  const [isCameraSelectionDialogOpen, setIsCameraSelectionDialogOpen] = useState(false);
  const [isBarcodeScannerDialogOpen, setIsBarcodeScannerDialogOpen] = useState(false);


  // for Camera Selection Dialog
  /* === CAMERA SELECTOR DIALOG LOGIC -- START -- === */
  const [cameras, setCameras] = useState<MediaDeviceInfo[]>([])
  const [selectedCamera, setSelectedCamera] = useState<string | null>(localStorage.getItem("selectedCamera"));

  const handleSwitchToScannerDialog = () => {
    setIsCameraSelectionDialogOpen(false);
    setIsBarcodeScannerDialogOpen(true);
  }

  /* === CAMERA SELECTOR DIALOG LOGIC -- END -- === */

  /* =========================================== */
  /* =========================================== */
  /* =========================================== */

  /* === BARCODE SCANNER DIALOG LOGIC -- START -- === */
  const videoRef = useRef<HTMLVideoElement>(null);
  const [currentVideoRef, setCurrentVideoRef] = useState<HTMLVideoElement | null>(null);
  const [scannedErrorText, setScannedErrorText] = useState<string>('');

  /* === BARCODE SCANNER DIALOG (FUNCTIONS) -- START -- === */
  const handleChangeCamera = async () => {
    stopCurrentStream();
    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = devices.filter((device) => device.kind === 'videoinput');
    setCameras(videoDevices);
    // Close Barcode Scanner Dialog
    setIsBarcodeScannerDialogOpen(false);

    // Open Camera selector Dialog
    setIsCameraSelectionDialogOpen(true);
    
  };

  const handleSuccessScan = (text: string) => {
    setScannedText(text);
    setIsCameraSelectionDialogOpen(false);
    onClose();
  };

  /* === BARCODE SCANNER DIALOG (FUNCTIONS) -- END -- === */

  /* === BARCODE SCANNER DIALOG LOGIC -- END -- === */

  /* main logic */

  const readerRef = useRef<BrowserMultiFormatReader | null>(null);
  const [currentReaderRef, setCurrentReaderRef] = useState<BrowserMultiFormatReader | null>(null);

  const [isCameraActive, setIsCameraActive] = useState(false);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);

  useEffect(() => {
    if (isOpen && !localStorage.getItem("selectedCamera")) {
      setIsCameraSelectionDialogOpen(true);
    }
    if (isOpen && localStorage.getItem("selectedCamera")) {
      setIsCameraSelectionDialogOpen(false);
      setIsBarcodeScannerDialogOpen(true);
    }

    return () => {
      stopCurrentStream();
      onClose();
    };
  }, [isOpen, localStorage]);

  const handleClose = () => {
    if (isCameraSelectionDialogOpen) setIsCameraSelectionDialogOpen(false);
    if (isBarcodeScannerDialogOpen) setIsBarcodeScannerDialogOpen(false);

    if (isCameraActive) {
      if (mediaStream) {
        mediaStream.getTracks().forEach((track) => track.stop());
        setMediaStream(null);
      }

      if (currentReaderRef) {
        currentReaderRef.reset();
        readerRef.current = null;
        setCurrentReaderRef(null);
      }

      if (currentVideoRef) {
        currentVideoRef.srcObject = null;
        setCurrentVideoRef(null);
      }
    }
  }

  const stopCurrentStream = () => {
    if (mediaStream) {
        mediaStream.getTracks().forEach((track) => track.stop());
        setMediaStream(null);
    }

    if (currentVideoRef) {
        currentVideoRef.srcObject = null;
        setCurrentVideoRef(null);
    }

    if (currentReaderRef) {
        currentReaderRef.reset();
        readerRef.current = null;
        setCurrentReaderRef(null);
    }
};
  return (
    <>
      <CameraSelectionDialog
        isOpen={isCameraSelectionDialogOpen}
        onClose={handleClose}
        cameras={cameras}
        setSelectedCamera={setSelectedCamera}
        selectedCamera={selectedCamera}
        title='Select your camera'
        setCameras={setCameras}
        switchToScannedDialog={handleSwitchToScannerDialog}
        mediaStream={mediaStream}
        readerRef={readerRef}
        videoRef={videoRef}
        setMediaStream={setMediaStream}
      />
      <BarcodeScannerDialog
        videoRef={videoRef}
        setCurrentVideoRef={setCurrentVideoRef}
        title='Scan Bar code'
        animated={animated}
        bordered={bordered}
        isOpen={isBarcodeScannerDialogOpen}
        onClose={handleClose}
        onChangeCamera={handleChangeCamera}
        onSuccessScan={handleSuccessScan}
        detectedText={scannedText}
        setDetectedText={setScannedText}
        scannedErrorText={scannedErrorText}
        setScannedErrorText={setScannedErrorText}
        readerRef={readerRef}
        setCurrentReaderRef={setCurrentReaderRef}
        setIsCameraActive={setIsCameraActive}
        mediaStream={mediaStream}
        setMediaStream={setMediaStream}
      />
    </>
  )
}

export default BarcodeDialogContainer