import { Alert, Button, Col, Divider, Input, Row, Select, Tooltip } from 'antd';
import { FC, useContext, useState } from 'react';
import { connect } from 'react-redux';
import {
  SET_ADD_NETWORK_DEVICE_MODAL_DATA,
  SET_ADD_NETWORK_DEVICE_MODAL_VISIBLE,
} from '../store/constants';
import { OrderNetworkDevicesContext } from '..';
import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaModuleTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.types';
import { getSchemaFromShortListByModuleAndEntity } from '../../../../../shared/utilities/schemaHelpers';
import { httpPost } from '../../../../../shared/http/requests';
import ModuleEntityIcon from '../../../../../core/theme/ModuleEntityIcon';

import { CameraOutlined } from '@ant-design/icons';
import BarcodeDialogContainer from '../BarcodeScanDialog/BarcodeDialogContainer';

interface Props {
  orderItem: DbRecordEntityTransform;
  schemaReducer: any;
  onUpdated?: () => void;
}

const { SERVICE_MODULE } = SchemaModuleTypeEnums;

const DeactivatedATA: FC<Props> = (props: Props) => {
  const { orderItem, schemaReducer, onUpdated } = props;
  const { state, dispatch } = useContext(OrderNetworkDevicesContext);
  const [serialNumber, setSerialNumber] = useState<string>('');
  const [isCreatingRecord, setIsCreatingRecord] = useState<boolean>(false);
  const [selectedModel, setSelectedModel] = useState<
    '621_I' | '621_X' | '622_V' | undefined
  >(undefined);
  const [error, setError] = useState<string>();

  /* === BarCode feature start === */
  const [isBarcodeModalOpen, setIsBarcodeModalOpen] = useState<boolean>(false);
  

  const handleOpenModal = () => {
    setIsBarcodeModalOpen(true);
  };

  /* ==== BarCode Feature End ==== */

  const handleSerialNumberChange = (e: any) => {
    setSerialNumber(e.target.value);
  };

  const schema = getSchemaFromShortListByModuleAndEntity(
    schemaReducer.shortList,
    SERVICE_MODULE,
    'CustomerDeviceAta',
  );

  const activateATA = () => {
    setIsCreatingRecord(true);

    httpPost(`ServiceModule/v2.0/network/ata/device/${orderItem.id}/link`, {
      entity: `${SERVICE_MODULE}:CustomerDeviceAta`,
      properties: {
        SerialNumber: serialNumber,
        Model: selectedModel,
      },
    })
      .then((res: any) => {
        setIsCreatingRecord(false);
        const response = res.data?.data;

        if (response?.conflict) {
          dispatch({
            type: SET_ADD_NETWORK_DEVICE_MODAL_VISIBLE,
            payload: true,
          });
          dispatch({
            type: SET_ADD_NETWORK_DEVICE_MODAL_DATA,
            payload: response,
          });
        } else {
          onUpdated && onUpdated();
        }
      })
      .catch((err: any) => {
        setError(
          err?.response?.data?.message ||
          err?.message ||
          'Unable to link device',
        );
        setIsCreatingRecord(false);
      });
  };

  return (
    <div
      style={{
        borderRadius: 2,
        background: '#f5f5f5',
        border: '1px solid #e2e2e2',
        padding: '15px 15px',
      }}
    >
      <Row justify="space-between" align="middle">
        <Col>
          <ModuleEntityIcon
            moduleName={SERVICE_MODULE}
            entityName={'CustomerDeviceAta'}
          />
          <span style={{ fontWeight: 600 }}>ATA Device</span>
        </Col>
        <Col>
          <Button
            type="primary"
            style={{ marginRight: 10 }}
            disabled={isCreatingRecord || (!serialNumber && !selectedModel)}
            size="small"
            ghost
            onClick={() => {
              setSerialNumber('');
              setSelectedModel(undefined);
            }}
          >
            Clear
          </Button>
          <Button
            icon={<i className="bi bi-power" />}
            type="primary"
            size="small"
            loading={isCreatingRecord}
            disabled={
              isCreatingRecord ||
              !schema ||
              !serialNumber ||
              serialNumber.length === 0 ||
              !selectedModel
            }
            onClick={activateATA}
          >
            Add
          </Button>
        </Col>
      </Row>
      <Row justify="space-between" gutter={12}>
        <Col span={24}>
          <Divider
            style={{ marginTop: 10, marginBottom: 15, borderColor: '#e2e2e2' }}
          />
        </Col>
        {error && (
          <Col span={24}>
            <Alert type="error" message={error} style={{ marginBottom: 14 }} />
          </Col>
        )}
        <Col xs={24} md={12}>
          <Input
            allowClear
            placeholder="Enter Serial No."
            value={serialNumber}
            onChange={handleSerialNumberChange}
            suffix={
              <Tooltip title="Scan bar code">
                <CameraOutlined
                  style={{ cursor: 'pointer', fontSize: 16 }}
                  onClick={() => handleOpenModal()}
                />
              </Tooltip>
            }
          />
        </Col>
        <Col xs={24} md={12}>
          <Select
            allowClear
            onClear={() => setSelectedModel(undefined)}
            style={{ width: '100%' }}
            placeholder="Select Model"
            value={selectedModel}
            onSelect={setSelectedModel}
          >
            <Select.Option value="GRANDSTREAM_HT801">
              GRANDSTREAM HT801
            </Select.Option>
          </Select>
        </Col>
      </Row>
      {/* Barcode Scanner Modal */}
      <BarcodeDialogContainer
        isOpen={isBarcodeModalOpen}
        onClose={() => setIsBarcodeModalOpen(false)}
        setScannedText={(value) => {
          setSerialNumber(value);
        }}
        scannedText={serialNumber}
        animated
        bordered
      />
    </div>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(DeactivatedATA);
